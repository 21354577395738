.form-container {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 height: 100vh;
	 font-family: Montserrat;
	 font-style: normal;
	 font-weight: 400;
	 font-size: 18px;
	 line-height: 112.9%;
}
.form-container .form-div {
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 padding: 45px;
	 background-color: #fff;
	 min-width: 260px;
	 max-width: 600px;
	 border-radius: 30px;
}
.form-container .form-title {
	 position: relative;
	 margin-bottom: 10px;
	 color: #314190;
	font-weight: 600;
	font-size: 22px;
	 text-transform: uppercase;
	 text-align: center;
}
.form-container .form-title-under {
	 margin-bottom: 26px;
	 color: #314190;
	 font-weight: 400;
}
.form-container .form-logo {
	 display: flex;
	 justify-content: center;
	 align-self: center;
	 padding: 15px 0;
	 width: 114px;
}
.form-container form {
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 width: 100%;
}
.form-container .form-label {
	 margin-bottom: 9px;
	 font-size: 16px;
	 color: #2e3f95;
}
.form-container .form-input {
	 display: block;
	 width: 100%;
	 padding: 9px 0;
	 outline: none;
	 font-size: 16px;
	 font-weight: 400;
	 text-indent: 2%;
	 border: 3px solid #7b97f8;
	 border-radius: 10px;
}
.form-container .form-label-checkbox {
	 position: relative;
	 padding: 0 0 0 60px;
	 cursor: pointer;
}
.form-container .lower-container {
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 margin-top: 6px;
}
.form-container .lower-container .forget-pass {
	 font-size: 14px;
	 color: #7b97f8;
	 font-weight: 400;
	 text-decoration: underline !important;
	 cursor: pointer;
}
.form-container .lower-container .forget-pass:hover {
	 color: #0817d0;
}
.form-container .submit-container {
	 position: relative;
}
.form-container .submit-container .submit {
	 position: relative;
	 display: flex;
	 padding: 10px 25px 10px 25px;
	 cursor: pointer;
	 color: #fff;
	 background-color: #68cf8c;
	 border: 1px solid #68cf8c;
	 border-radius: 30px;
	 outline: none;
	 transition: 0.3s;
	 font-weight: 600;
	 font-size: 16px;
}
.form-container .submit-container .submit:hover {
	 background-color: #4c579a;
	 border: 1px solid #4c579a;
}
.input-container {
	 margin-bottom: 22px;
}
.is-danger {
	 border: 2px solid #faa86c !important;
}
.form-error {
	 margin: 0;
	 font-size: 14px;
	 line-height: 18px;
	 color: #fe8e15;
	 font-family: 'Arimo';
	 font-weight: 400;
}
 